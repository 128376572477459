import React from 'react'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Carousel from '../components/carousel'

import letter from '../images/hero-contact.jpg'
const ContactInfo = ({ title, path, info }) => (
  <div className="about--icons">
    <svg className="about--icon" viewBox="0 0 32 32">
      <title>{title}</title>
      <path d={path}></path>
    </svg>
    <div className="about--info">{info}</div>
  </div>
)
const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Carousel
      title={<FormattedMessage id="navbar-4" />}
      subtitle={<FormattedMessage id="contact--subtitle" />}
      size={'is-medium'}
      // color={"is-primary"}
      img={letter}
      imgalt="Our classroom"
    />
    <div className="contact--container main">
      <div className="card">
        <div className="card-content">
          <form
            action="https://formsubmit.co/info@smartnative.hk"
            method="POST"
          >
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact--form-field1" />
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="Name"
                  aria-label="Your Name"
                  placeholder="Smarty"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact--form-field2" />
              </label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="Email"
                  aria-label="Your Email"
                  placeholder="smarty@gmail.com"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact--form-field3" />
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="Message"
                  aria-label="Your Message"
                  placeholder="Great teachers!"
                  required
                ></textarea>
              </div>
            </div>
            <div className="control">
              <button type="submit" id="contact--button" className="button">
                <FormattedMessage id="contact--form-button" />
              </button>
            </div>
            <input type="hidden" name="_captcha" value="false" />
            <input
              type="hidden"
              name="_next"
              value="https://smartnative.hk/thanks"
            />
          </form>

          <section className="section">
            <div className="title">
              {' '}
              <FormattedMessage id="contact--container-heading" />
            </div>
            <div className="container">
              <ContactInfo
                title="Phone"
                path="M22 20c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.109 12.109 8 16s12 8 16 8c0 0 6-4 6-6s-6-8-8-6z"
                info="(852) 3996 8188"
              />
              <ContactInfo
                title="Whatsapp"
                path="M27.281 4.65c-2.994-3-6.975-4.65-11.219-4.65-8.738 0-15.85 7.112-15.85 15.856 0 2.794 0.731 5.525 2.119 7.925l-2.25 8.219 8.406-2.206c2.319 1.262 4.925 1.931 7.575 1.931h0.006c0 0 0 0 0 0 8.738 0 15.856-7.113 15.856-15.856 0-4.238-1.65-8.219-4.644-11.219zM16.069 29.050v0c-2.369 0-4.688-0.637-6.713-1.837l-0.481-0.288-4.987 1.306 1.331-4.863-0.313-0.5c-1.325-2.094-2.019-4.519-2.019-7.012 0-7.269 5.912-13.181 13.188-13.181 3.519 0 6.831 1.375 9.319 3.862 2.488 2.494 3.856 5.8 3.856 9.325-0.006 7.275-5.919 13.188-13.181 13.188zM23.294 19.175c-0.394-0.2-2.344-1.156-2.706-1.288s-0.625-0.2-0.894 0.2c-0.262 0.394-1.025 1.288-1.256 1.556-0.231 0.262-0.462 0.3-0.856 0.1s-1.675-0.619-3.188-1.969c-1.175-1.050-1.975-2.35-2.206-2.744s-0.025-0.613 0.175-0.806c0.181-0.175 0.394-0.463 0.594-0.694s0.262-0.394 0.394-0.662c0.131-0.262 0.069-0.494-0.031-0.694s-0.894-2.15-1.219-2.944c-0.319-0.775-0.65-0.669-0.894-0.681-0.231-0.012-0.494-0.012-0.756-0.012s-0.694 0.1-1.056 0.494c-0.363 0.394-1.387 1.356-1.387 3.306s1.419 3.831 1.619 4.1c0.2 0.262 2.794 4.269 6.769 5.981 0.944 0.406 1.681 0.65 2.256 0.837 0.95 0.3 1.813 0.256 2.494 0.156 0.762-0.113 2.344-0.956 2.675-1.881s0.331-1.719 0.231-1.881c-0.094-0.175-0.356-0.275-0.756-0.475z"
                info="(852) 5742 9598"
              />
              <ContactInfo
                title="Email"
                path="M28 5h-24c-2.209 0-4 1.792-4 4v13c0 2.209 1.791 4 4 4h24c2.209 0 4-1.791 4-4v-13c0-2.208-1.791-4-4-4zM2 10.25l6.999 5.25-6.999 5.25v-10.5zM30 22c0 1.104-0.898 2-2 2h-24c-1.103 0-2-0.896-2-2l7.832-5.875 4.368 3.277c0.533 0.398 1.166 0.6 1.8 0.6 0.633 0 1.266-0.201 1.799-0.6l4.369-3.277 7.832 5.875zM30 20.75l-7-5.25 7-5.25v10.5zM17.199 18.602c-0.349 0.262-0.763 0.4-1.199 0.4s-0.851-0.139-1.2-0.4l-12.8-9.602c0-1.103 0.897-2 2-2h24c1.102 0 2 0.897 2 2l-12.801 9.602z"
                info="info@smartnative.hk"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
